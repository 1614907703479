<template>
  <div v-if="show" class="cart-popup">
    <div class="cart-popup-content">
      <div class="cart-items-container">
        <h5>{{ $t('ui.item_added_to_cart') }}</h5>
        <div v-for="item in cartItems" :key="item.variantId" class="cart-items">
          <img :src="item.image" alt="Product image" />
          <div>
            <h6>{{ item.name }}</h6>
            <p>{{ item.options }}</p>
            <p>{{ item.quantity }} x {{ formatMoney({ amount: item.price }) }}</p>
          </div>
        </div>
      </div>

      <div class="cart-buttons">
        <button class="btn" @click="goToCart">{{ $t('ui.go_to_cart') }}</button>
        <button class="btn btn-ghost" @click="closePopup">{{ $t('ui.continue_shopping') }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCartStore } from '../../stores/cart.js';
import { formatMoney } from '../../libs/utils.js';

const cartStore = useCartStore();
defineProps({
  show: { type: Boolean, default: false }
});
const emit = defineEmits(['update:show']);

const router = useRouter();
const cartItems = computed(() => cartStore.purchases);

const closePopup = () => {
  emit('update:show', false);
};

const goToCart = () => {
  closePopup();
  router.push('/shop/cart');
};
</script>

<style scoped lang="scss">
h5 {
  margin-block: 15px 0;
  margin-inline: 35px;
}
.cart-popup {
  position: fixed;
  top: 12px;
  right: 12px;
  background-color: white;
  min-width: 380px;
  max-width: 450px;
  border: 1px solid #ddd;
  border-radius: var(--base-radius);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.cart-popup-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 24px - 70px);
}

.cart-items-container {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 5px;
}

.cart-items {
  display: flex;
  align-items: center;
  margin-block: 15px;
  padding-inline: 35px;
}

.cart-items img {
  grid-area: image;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--base-radius);
  overflow: hidden;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
  margin-right: 5px;
}

.cart-buttons {
  position: sticky;
  bottom: 0;
  background-color: $brand-light-grey;
  border-top: 1px solid $brand-blue;
  padding-block: 20px;
  padding-inline: 35px;
  margin-top: auto;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

h6,
p {
  margin: 0;
}
</style>
